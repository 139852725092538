import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import { Container, Row, Col } from 'react-bootstrap';
import SitemapCategory from 'components/SitemapCategory';
import createBreadcrumbs from 'utils/createBreadcrumbs';
import sitemapGenerator from 'utils/sitemapGenerator';
import SectionTitle from 'common/SectionTitle';
import 'styles/main.scss';
import './SitemapPage.scss';

const SitemapPage = ({
  data: {
    sitemap: { title, seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  const sitemapMatrix = sitemapGenerator(nodes);

  return (
    <Layout
      headerTransparent
      breadcrumbs={breadcrumbs}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex }}
    >
      <Container className="sitemap-page">
        <Row>
          <Col>
            <SectionTitle text={title} isMainTitle />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs="12" xl="10" lg="10">
            <div className="sitemap-page__container">
              {sitemapMatrix.map((item) => (
                <div key={item.link} className="sitemap-page__category-wrapper">
                  <SitemapCategory key={item.link} {...item} />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  {
    sitemap {
      title
      seoNoIndex
      seoMetaTitle
      seoMetaDescription
    }
    allSitePage {
      nodes {
        path
        context {
          breadcrumbName
          breadcrumb {
            crumbs {
              pathname
              crumbLabel
            }
          }
        }
      }
    }
  }
`;

export default SitemapPage;
