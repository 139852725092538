const pathsToExclude = ['/dev-404-page/', '/404/', '/404.html', '/sitemap', '/'];

const extremePathSlashes = /^\/?|\/?$/g;

const sitemapGenerator = (data, level = 1) =>
  data
    .filter((node) => !pathsToExclude.includes(node.path))
    .reduce((acc, siteNode) => {
      const paths = siteNode.path.replace(extremePathSlashes, '').split('/');
      const isSitemapCategoryAdded = acc.find((el) => el.link.includes(paths[0]));

      const name =
        siteNode.context.breadcrumbName ||
        siteNode.context.breadcrumb.crumbs[siteNode.context.breadcrumb.crumbs.length - 1]
          .crumbLabel;

      if (!isSitemapCategoryAdded) {
        acc.push({
          link: siteNode.path,
          name,
          children: [],
          level,
        });
      }

      if (isSitemapCategoryAdded) {
        if (paths.length === level + 1) {
          isSitemapCategoryAdded.children.push({
            link: siteNode.path,
            name,
            level: level + 1,
            children: [],
          });
        }

        if (paths.length > level + 1) {
          const hasNextLevel = isSitemapCategoryAdded.children.find((i) =>
            siteNode.path.includes(i.link)
          );

          if (hasNextLevel) {
            hasNextLevel.children = [
              ...sitemapGenerator([siteNode], level + 2),
              ...hasNextLevel.children,
            ];
          }
        }
      }

      return acc;
    }, []);

export default sitemapGenerator;
