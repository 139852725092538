import React, { FC } from 'react';
import { Link } from 'gatsby';

import classNames from 'classnames';
import { SitemapCategoryProps } from './models';
import './SitemapCategory.scss';

const SitemapCategory: FC<SitemapCategoryProps> = ({ name, link, level, children }) => (
  <div className="sitemap-category">
    <Link className={classNames('sitemap-category__link', `level-${level}`)} to={link}>
      {name}
    </Link>

    {children?.length
      ? children.map((childCategory) => (
          <SitemapCategory key={childCategory.link} {...childCategory} />
        ))
      : null}
  </div>
);

export default SitemapCategory;
